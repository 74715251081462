import React from "react";
import "./NavBar.css";
import { Link,Outlet } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function NavBar() {
  return (
    <div> 
    <nav>
      <input type="checkbox" id="check" />
      <label htmlFor="check" className="checkbtn">
        <i className="fas fa-bars"></i>
      </label>
      <Link to="/" className="logo">
        Jack's Blog
      </Link>
      <ul>
        <li>
          <Link className="active" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <Link to="/thoughts">Thoughts</Link>
        </li>
        <li>
          <Link to="/travel">Travel</Link>
        </li>
      </ul>
    </nav>
    <Outlet/>
    </div>
  );
}
