import React from "react";
import './Header.css';
import profilePic from "../assets/profile-pic.jpeg";


export default function Header() {
  return (
    <header>
      <img src = {profilePic} alt = "profile" className = "profile_pic"/> 
    </header>
  );
}