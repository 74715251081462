import "./App.css";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  // return (
  //   <div className="background">
  //     <div className="App">
  //       <NavBar/>
  //       <Home />
  //     </div>
  //   </div>
  // );

  return (
    <div className="background">
      <div className="App">
        <Router>
          <Routes>
            <Route element={<NavBar />}>
              <Route path="/" end element={<Home />} />
              <Route path="/projects" end element={<Projects />} />
              {/* <Route path="/about" element={<About />} />
            <Route path="/travel" element={<Travel />} /> */}
            </Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
