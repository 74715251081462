import React from "react";
import "./Detail.css";

function Detail() {
  return (
    <div className = "business-card">
      <h1 className = "name">Jack Puntawong</h1>
      <div className="titles">
        <span className="title">Machine Learning Engineer</span>
        <span className="title">Data Scientist</span>
        <span className="title">Wharton MBA</span>
      </div>
    </div>
  );
}

export default Detail;
