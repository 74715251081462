// project page that list out all of the projects

import Header from "../components/Header";
import Detail from "../components/Detail";
import Contact from "../components/Contact";
import Content from "../components/Content";
import "./Projects.css";




export default function Projects() {
  return (
    <div className="Projects">
        <h1> Projectsss </h1>
    </div>
  );
}
