import React from "react";
import "./Content.css"; // Assuming you are using a separate CSS file

function About() {
  return (
    <div className = "About">
      <h2>About</h2>
      <p> I'm a machine learning engineer and data scientist,
         specializing in deep learning with a solid background
          in finance and business. I've successfully created and
           deployed machine learning models to millions of users.
            Beyond tech, I've founded a bakery and built Thailand's 
            first nuclear fusion reactor.
      </p>
    </div>
  );
}

function Interests() {
  return (
    <div className = "Interests">
      <h2>Interests</h2>
      <p> Scuba diving, Photography, Pottery, Blacksmithing 
      </p>
    </div>
  );
}

export default function Content() {
  return (
    <div className="content">
      <About />
      <Interests />

    </div>
  );
}
