import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';  // Assuming you are using a separate CSS file

function handleLinkedInClick() {
  // Redirects user to LinkedIn profile
  window.open('https://www.linkedin.com/in/jack-puntawong/', '_blank');
}

function handleEmailClick() {
  window.location.href = "mailto:jackpuntawong@gmail.com";
}

export default function Content() {
  return (
    <div className = "contact">
      <button className="email-button">
        <FontAwesomeIcon icon={faEnvelope} onClick ={handleEmailClick} />
        <span>Email</span>
      </button>
      <button className="linkedin-button" onClick={handleLinkedInClick}>
        <FontAwesomeIcon icon={faLinkedinIn} />
        <span>LinkedIn</span>
      </button>
    </div>
  );
};