
import Header from "../components/Header";
import Detail from "../components/Detail";
import Contact from "../components/Contact";
import Content from "../components/Content";
import "./Home.css";

function Home() {
  return (
    <div className="Home">
        <Header />
        <Detail />
        <Contact />
        <Content />
    </div>
  );
}

export default Home;